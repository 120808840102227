<template>
    <main>
        <b-modal id="modal-profile" title="Profile information" v-model="modalShow" ok-only centered>
            <p class="my-4">{{ modalMessage }}</p>
        </b-modal>
    
        <b-card
            no-body
            class="member-info"
        >
            <b-card-header>
                <b-card-title class="text-success">{{$t(`message.profile_info`)}}</b-card-title>
                <b-card-title class="text-primary">
                {{ today }}
                </b-card-title>
            </b-card-header>
            <b-card-body>
                <!-- form -->
                <b-form @submit.prevent="submitMetaData" v-if="memberEmail">
                    <b-row>
                        <b-col md="6">
                        <b-form-group
                            :label='$t(`message.sft_contact_fullname`)'
                            label-for="member_name"
                            class="mb-2"
                        >
                            <b-form-input
                            v-model="memberName"
                            id="member_name"
                            type="text"
                            required
                            disabled
                            />
                        </b-form-group>
                        </b-col>
                        <b-col md="6">
                        <b-form-group
                            :label='$t(`message.sft_contact_mail`)'
                            label-for="member_email"
                            class="mb-2"
                        >
                            <b-form-input
                            v-model="memberEmail"
                            id="member_email"
                            type="email"
                            required
                            disabled
                            />
                        </b-form-group>
                        </b-col>
                        <b-col md="6">
                        <b-form-group
                            :label='$t(`message.sft_contact_phone`)'
                            label-for="member_phone"
                            class="mb-2"
                        >
                            <b-form-input
                            v-model="memberPhone"
                            id="member_phone"
                            type="tel"
                            required
                            />
                        </b-form-group>
                        </b-col>
                        <b-col md="6">
                        <b-form-group
                            :label='$t(`message.sft_contact_address`)'
                            label-for="member_address"
                            class="mb-2"
                        >
                            <b-form-input
                            v-model="memberAddress"
                            id="member_address"
                            type="text"
                            required
                            />
                        </b-form-group>
                        </b-col>
                        <b-col md="6">
                        <b-form-group
                            :label='$t(`message.zip_code`)'
                            label-for="member_zipcode"
                            class="mb-2"
                        >
                            <b-form-input
                            v-model="memberZipCode"
                            id="member_zipcode"
                            type="text"
                            required
                            />
                        </b-form-group>
                        </b-col>
                        <b-col md="6">
                        <b-form-group
                            :label='$t(`message.city`)'
                            label-for="member_city"
                            class="mb-2"
                        >
                            <b-form-input
                            v-model="memberCity"
                            id="member_city"
                            type="text"
                            required
                            />
                        </b-form-group>
                        </b-col>
                        <b-col md="6">
                        <b-form-group
                            :label='$t(`message.country`)'
                            label-for="member_country"
                            class="mb-2"
                        >
                            <v-select
                            id="member_country"
                            v-model="memberCountry"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="countries"
                            label="text"
                            />
                        </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <b-button
                                type="submit"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                            >
                                {{$t(`message.save_changes`)}}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
                <div v-else class="d-flex align-items-center justify-content-around my-2">
                    <b-spinner variant="success" style="width: 3rem; height: 3rem;"></b-spinner>
                </div>
            </b-card-body>
        </b-card>
        <b-card
            no-body
            class="member-password"
        >
            <b-card-header>
                <b-card-title class="text-success">{{$t(`message.upd_pass`)}}</b-card-title>
                <b-card-text class="text-primary">
                    <span class="text-danger">{{$t(`message.pass_length`)}}</span>
                </b-card-text>
            </b-card-header>
            <b-card-body>
                <!-- form -->
                <b-form @submit.prevent="submitPassword">
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label='$t(`message.new_pass`)'
                            label-for="member_new_pass"
                            class="position-relative"
                        >
                            <b-form-input
                            v-model="new_pass"
                            id="member_new_pass"
                            :type="password_type"
                            required
                            minlength="8"
                            />
                            <font-awesome-icon @click="toggleInpt" class="fontPass" :icon="['fasr', 'eye']" ref="f_pass" />

                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label='$t(`message.repeat_pass`)'
                            label-for="member_pass_repeat"
                            class="position-relative"
                        >
                            <b-form-input
                            v-model="new_pass_repeat"
                            id="member_pass_repeat"
                            :type="password_type_r"
                            required
                            minlength="8"
                            />
                        <font-awesome-icon @click="toggleInptR" class="fontPass" :icon="['fasr', 'eye']" />
                        <small class="text-danger">{{ same_pass }}</small>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" style="margin-top:10px;">
                        <b-button
                            type="submit"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                        >
                            {{$t(`message.change_pass`)}}
                        </b-button>
                    </b-col>
                </b-row>
                </b-form>
                <!--/ form -->
            </b-card-body>
        </b-card>
    </main>
   
  </template>
  
  <script>
  import { cookie, token_id, ls_email, ls_username } from '@/products';
  import vSelect from 'vue-select'
  import {
    BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton, BForm, BFormSelect, BCardText, BSpinner, BModal
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  
  export default {
    components: {
        vSelect,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardBody,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BFormSelect,
        BCardText,
        BSpinner,
        BModal,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            password_type: 'password',
            password_type_r: 'password',
            same_pass: null,
            new_pass: '',
            new_pass_repeat: '',
            modalShow: false,
            modalMessage: '',
            today: new Date().toLocaleDateString(),
            memberName: '',
            memberEmail: '',
            memberCountry: '',
            memberPhone: '',
            memberAddress: '',
            memberZipCode: '',
            memberCity: '',
            countries: [
                {text:"Afghanistan"},
                {text:"Åland Islands"},
                {text:"Albania"},
                {text:"Algeria"},
                {text:"American Samoa"},
                {text:"AndorrA"},
                {text:"Angola"},
                {text:"Anguilla"},
                {text:"Antarctica"},
                {text:"Antigua and Barbuda"},
                {text:"Argentina"},
                {text:"Armenia"},
                {text:"Aruba"},
                {text:"Australia"},
                {text:"Austria"},
                {text:"Azerbaijan"},
                {text:"Bahamas"},
                {text:"Bahrain"},
                {text:"Bangladesh"},
                {text:"Barbados"},
                {text:"Belarus"},
                {text:"Belgium"},
                {text:"Belize"},
                {text:"Benin"},
                {text:"Bermuda"},
                {text:"Bhutan"},
                {text:"Bolivia"},
                {text:"Bosnia and Herzegovina"},
                {text:"Botswana"},
                {text:"Bouvet Island"},
                {text:"Brazil"},
                {text:"British Indian Ocean Territory"},
                {text:"Brunei Darussalam"},
                {text:"Bulgaria"},
                {text:"Burkina Faso"},
                {text:"Burundi"},
                {text:"Cambodia"},
                {text:"Cameroon"},
                {text:"Canada"},
                {text:"Cape Verde"},
                {text:"Cayman Islands"},
                {text:"Central African Republic"},
                {text:"Chad"},
                {text:"Chile"},
                {text:"China"},
                {text:"Christmas Island"},
                {text:"Cocos (Keeling) Islands"},
                {text:"Colombia"},
                {text:"Comoros"},
                {text:"Congo"},
                {text:"CongoDemocratic Republic of the"},
                {text:"Cook Islands"},
                {text:"Costa Rica"},
                {text:"Cote D\"Ivoire"},
                {text:"Croatia"},
                {text:"Cuba"},
                {text:"Cyprus"},
                {text:"Czech Republic"},
                {text:"Denmark"},
                {text:"Djibouti"},
                {text:"Dominica"},
                {text:"Dominican Republic"},
                {text:"Ecuador"},
                {text:"Egypt"},
                {text:"El Salvador"},
                {text:"Equatorial Guinea"},
                {text:"Eritrea"},
                {text:"Estonia"},
                {text:"Ethiopia"},
                {text:"Falkland Islands (Malvinas)"},
                {text:"Faroe Islands"},
                {text:"Fiji"},
                {text:"Finland"},
                {text:"France"},
                {text:"French Guiana"},
                {text:"French Polynesia"},
                {text:"French Southern Territories"},
                {text:"Gabon"},
                {text:"Gambia"},
                {text:"Georgia"},
                {text:"Germany"},
                {text:"Ghana"},
                {text:"Gibraltar"},
                {text:"Greece"},
                {text:"Greenland"},
                {text:"Grenada"},
                {text:"Guadeloupe"},
                {text:"Guam"},
                {text:"Guatemala"},
                {text:"Guernsey"},
                {text:"Guinea"},
                {text:"Guinea-Bissau"},
                {text:"Guyana"},
                {text:"Haiti"},
                {text:"Heard Island and Mcdonald Islands"},
                {text:"Holy See (Vatican City State)"},
                {text:"Honduras"},
                {text:"Hong Kong"},
                {text:"Hungary"},
                {text:"Iceland"},
                {text:"India"},
                {text:"Indonesia"},
                {text:"Iranmic Republic Of"},
                {text:"Iraq"},
                {text:"Ireland"},
                {text:"Isle of Man"},
                {text:"Israel"},
                {text:"Italy"},
                {text:"Jamaica"},
                {text:"Japan"},
                {text:"Jersey"},
                {text:"Jordan"},
                {text:"Kazakhstan"},
                {text:"Kenya"},
                {text:"Kiribati"},
                {text:"Koreacratic People\"S Republic of"},
                {text:"Koreablic of"},
                {text:"Kuwait"},
                {text:"Kyrgyzstan"},
                {text:"Lao People\"S Democratic Republic"},
                {text:"Latvia"},
                {text:"Lebanon"},
                {text:"Lesotho"},
                {text:"Liberia"},
                {text:"Libyan Arab Jamahiriya"},
                {text:"Liechtenstein"},
                {text:"Lithuania"},
                {text:"Luxembourg"},
                {text:"Macao"},
                {text:"MacedoniaFormer Yugoslav Republic of"},
                {text:"Madagascar"},
                {text:"Malawi"},
                {text:"Malaysia"},
                {text:"Maldives"},
                {text:"Mali"},
                {text:"Malta"},
                {text:"Marshall Islands"},
                {text:"Martinique"},
                {text:"Mauritania"},
                {text:"Mauritius"},
                {text:"Mayotte"},
                {text:"Mexico"},
                {text:"Micronesiarated States of"},
                {text:"Moldovablic of"},
                {text:"Monaco"},
                {text:"Mongolia"},
                {text:"Montserrat"},
                {text:"Morocco"},
                {text:"Mozambique"},
                {text:"Myanmar"},
                {text:"Namibia"},
                {text:"Nauru"},
                {text:"Nepal"},
                {text:"Netherlands"},
                {text:"Netherlands Antilles"},
                {text:"New Caledonia"},
                {text:"New Zealand"},
                {text:"Nicaragua"},
                {text:"Niger"},
                {text:"Nigeria"},
                {text:"Niue"},
                {text:"Norfolk Island"},
                {text:"Northern Mariana Islands"},
                {text:"Norway"},
                {text:"Oman"},
                {text:"Pakistan"},
                {text:"Palau"},
                {text:"Palestinian Territorypied"},
                {text:"Panama"},
                {text:"Papua New Guinea"},
                {text:"Paraguay"},
                {text:"Peru"},
                {text:"Philippines"},
                {text:"Pitcairn"},
                {text:"Poland"},
                {text:"Portugal"},
                {text:"Puerto Rico"},
                {text:"Qatar"},
                {text:"Reunion"},
                {text:"Romania"},
                {text:"Russian Federation"},
                {text:"RWANDA"},
                {text:"Saint Helena"},
                {text:"Saint Kitts and Nevis"},
                {text:"Saint Lucia"},
                {text:"Saint Pierre and Miquelon"},
                {text:"Saint Vincent and the Grenadines"},
                {text:"Samoa"},
                {text:"San Marino"},
                {text:"Sao Tome and Principe"},
                {text:"Saudi Arabia"},
                {text:"Senegal"},
                {text:"Serbia and Montenegro"},
                {text:"Seychelles"},
                {text:"Sierra Leone"},
                {text:"Singapore"},
                {text:"Slovakia"},
                {text:"Slovenia"},
                {text:"Solomon Islands"},
                {text:"Somalia"},
                {text:"South Africa"},
                {text:"South Georgia and the South Sandwich Islands"},
                {text:"Spain"},
                {text:"Sri Lanka"},
                {text:"Sudan"},
                {text:"Suriname"},
                {text:"Svalbard and Jan Mayen"},
                {text:"Swaziland"},
                {text:"Sweden"},
                {text:"Switzerland"},
                {text:"Syrian Arab Republic"},
                {text:"Taiwanince of China"},
                {text:"Tajikistan"},
                {text:"Tanzaniaed Republic of"},
                {text:"Thailand"},
                {text:"Timor-Leste"},
                {text:"Togo"},
                {text:"Tokelau"},
                {text:"Tonga"},
                {text:"Trinidad and Tobago"},
                {text:"Tunisia"},
                {text:"Turkey"},
                {text:"Turkmenistan"},
                {text:"Turks and Caicos Islands"},
                {text:"Tuvalu"},
                {text:"Uganda"},
                {text:"Ukraine"},
                {text:"United Arab Emirates"},
                {text:"United Kingdom"},
                {text:"United States"},
                {text:"United States Minor Outlying Islands"},
                {text:"Uruguay"},
                {text:"Uzbekistan"},
                {text:"Vanuatu"},
                {text:"Venezuela"},
                {text:"Viet Nam"},
                {text:"Virgin Islandsish"},
                {text:"Virgin Islands"},
                {text:"Wallis and Futuna"},
                {text:"Western Sahara"},
                {text:"Yemen"},
                {text:"Zambia"},
                {text:"Zimbabwe"},
            ],
            token_id,
            ls_email,
            ls_username,
            cookie
        }
    },
    async created() {
        this.getMetaData();
    },
    methods: {
        async getMetaData() {
            const url= {
                prod: 'https://api-quote.safetravel.today/v2/app/get_member_metadata'
            };
            const data = {
                token: this.token_id,
                email: this.ls_email,
                jwt: this.cookie
            }
            try {
                await fetch(url.prod, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                })
                .then((response)=>response.json())
                .then((res)=> {
                    if (res === 'not_valid') {
                        window.location.href = '/logout';
                        return;
                    }
                    if (res) {
                        this.memberName = res?.meta_full_name ?? '';
                        this.memberEmail = res?.meta_email ?? '';
                        this.memberPhone = res?.meta_telephone ?? '';
                        this.memberAddress = res?.meta_address ?? '';
                        this.memberZipCode = res?.meta_zipcode ?? '';
                        this.memberCity = res?.meta_city ?? '';
                        this.memberCountry = res?.meta_country ?? '';
                    } else {
                        this.modalDts('Profile information is not available.');
                    }
                })
            } catch (error) {
                // TypeError: Failed to fetch
                console.warn('There was an error to fetch get_member_metadata', error);
                this.modalDts('Profile information is not available.');
            }
        },
        async submitMetaData() {
            if (this.memberName && this.memberEmail) {
                const url= {
                    prod: 'https://api-quote.safetravel.today/v2/app/update_member_metadata'
                };
                const data = {
                    token: this.token_id,
                    meta_country: this.memberCountry?.text ? this.memberCountry?.text : 'Greece' ?? this.memberCountry,
                    meta_telephone: this.memberPhone ?? '',
                    meta_address: this.memberAddress ?? '',
                    meta_zipcode: this.memberZipCode ?? '',
                    meta_city: this.memberCity ?? '',
                    jwt: this.cookie
                }
                try {
                    await fetch(url.prod, {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(data)
                    })
                    .then((response)=>response.json())
                    .then((res)=> {
                        if (res === 'not_valid') {
                            window.location.href = '/logout';
                            return;
                        }           
                        // update store
                        this.modalDts('Your profile information have been updated.');
                    })
                } catch (error) {
                    // TypeError: Failed to fetch
                    console.warn('There was an error to update member_metadata', error);
                    // modal error
                    this.modalDts('Your profile information could not be updated.');
                }
            } else {
                // modal error
                this.modalDts('Your profile information could not be updated.');
            }
        },
        modalDts(msg) {
            this.modalShow = true;
            this.modalMessage = msg;
        },
        async submitPassword() {
            if (this.new_pass !== this.new_pass_repeat) {
                this.same_pass = 'Password and confirm password does not match'
                return;
            }
            this.same_pass = null;
            if (this.ls_username) {
                // update_member_password
                const url= {
                    prod: 'https://api-quote.safetravel.today/v2/app/update_member_password'
                };
                const data = {
                    token: this.token_id,
                    password: this.new_pass,
                    username: this.ls_username,
                    jwt: this.cookie
                }
                try {
                    await fetch(url.prod, {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(data)
                    })
                    .then((response)=> {
                        return response.json();
                    })
                    .then((res)=> {
                        if (res === 'not_valid') {
                            window.location.href = '/logout';
                            return;
                        }
                        // modal success
                        if (res == 'success') {
                            this.modalDts('Your password have been updated. Please log in again.');
                            setTimeout(()=> window.location.href = '/logout',2000);
                        }
                    })
                } catch (error) {
                    // TypeError: Failed to fetch
                    console.warn('There was an error to update member_password', error);
                    // modal error
                    this.modalDts('Your password could not be updated.');
                }
            }
        },
        toggleInpt() {
            if (this.password_type === 'password') {
                this.password_type = 'text'
            } else {
                this.password_type = 'password'
            }
        },
        toggleInptR() {
            if (this.password_type_r === 'password') {
                this.password_type_r = 'text'
            } else {
                this.password_type_r = 'password'
            }
        }
    },
  }
  </script>
  <style scoped>
    .fontPass {
        position: absolute;
        right: 5px;
        top: calc(50% + 0.25em);
        cursor: pointer;
    }
  </style>